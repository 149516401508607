













import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator"
import groupmembers from "@/components/member/groupmembers.vue"

@Component({
  components: {
    groupmembers
  }
})
export default class GroupsTable extends Vue {
  @Prop()
  transmitType: string

  TYPE: Array<any> = [{
    label: '已激活',
    name: '1'
  }, {
    label: '未激活',
    name: '0'
  }]
  query: any = {
    verified: '1'
  }
  @Emit("handleClick")
  handleClick(tab, event) {}

  @Emit("getMemberId")
  handleSelectionChange(payload) {}

  @Watch('$route.query.verified')
  onStatusChanged(val: any, oldVal: any) {
    if (val) {
      this.query.verified = val
    } else {
      this.query.verified = '1'
    }
  }
}
