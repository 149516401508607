

















































import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";
import utils from "@/utils/util.ts";
import { Getter, Action } from "vuex-class";
import bus from '@/common/bus'

@Component({
  props: {
    verified: Number
  }
})
export default class ASComponentsMerberGroupMembers extends Vue {
  @Getter("grouptotal") grouptotal: Number
  @Getter("groupList") groupList: Object[]

  @Prop()
  transmitType: string 

  verified: number
  currentPage4: number = 1
  pagination: any = {
    page: 1,
    size: 10
  }
  dataList: any[]
  multipleSelection: any = []
  keyword: String
  project: any = {}

  @Watch('transmitType')
  getCurrType(old:any,newValue:any) {
    if(old === 'groups') {
      this.dataList = this.groupList
    } else if (old === 'managers') {
      this.dataList = this.groupList
    }
  }

  mounted() {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.SeacherMember()
  }
  @Emit("handleChange")
  handleSelectionChange(val: Object) {
    this.multipleSelection = val
  }
  onPagerSizeChange(val: string) {
    this.pagination.size = val
  }
  onPagerCurrentChange(val: string) {
    this.pagination.page = val
  }

  SeacherMember() {
    bus.$on('searchgroup',val => {
      this.keyword = val
    })
  }
}
