






































import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator"

@Component({})
export default class Buttonarray extends Vue {
  @Prop()
  transmitType: string 

  keyword: String = ''
  project: any = {}
  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
  }
  get rejectOrPass () {
    return this.project.role === 'professor' || this.project.role === 'leader'
  }
  @Emit('changeRole')
  changeRoleFuc() {}

  @Emit('forbid')
  forbidden() {}

  @Emit('enable')
  enable () {}

  @Emit('removeAdmin')
  removeFuc() {}

  @Emit('addNewAdmin')
  addNewAdmin(type: String) {}

  @Emit('keywordSearch')
  doKeywordSearch(val: String) {}

  @Watch('transmitType')
  cleatKey(old: any, newValue: any) {
    this.keyword = ''
  }
}
