











































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { Getter, Action } from "vuex-class"
import utils from "@/utils/util.ts"
import buttonArray from "@/components/member/ButtonArray.vue"
import groupsTable from "@/components/member/GroupsTable.vue"
import bus from '@/common/bus'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    buttonArray,
    groupsTable
  },
  mixins: [globalMixins]
})
export default class ATSViewsMemberGroups extends Vue<globalMixins> {
  @Getter("grouptotal") grouptotal: Number
  @Getter("keySearchLists") keySearchLists: any[]
  @Action("changeRole") changeRole: Function
  @Action("groupsList") groupsList: Function
  @Action("usersList") usersList: Function;
  @Action("forbiddenUser") forbiddenUser: Function
  @Action("addUsers") addUsers: Function
  @Action('findUserBeLeader') findUserBeLeader: Function
  @Action('projectGroups') projectGroups: Function
  @Getter('projGroups') projGroups: Object[]
  @Getter('asideConfigs') asideConfigs: Object
  
  urls: string = ''
  types: string = null
  currentPage4: number = 1
  pagination: any = {
    page: 1,
    size: 10
  }
  memberType: string = ''
  account: any = JSON.parse(localStorage.getItem("account"))
  keyword: String
  project: any = {}
  verified: string = '1'
  user_ids: any[]
  admin_ids: any[]
  dialogFormVisible: boolean = false
  form: object = {
    name: '',
    password: '',
    gender: '',
    email: '',
    organization: '',
    position: '',
    phone: '',
    group_id: null
  }
  isPasShow: boolean = true
  role: string = ''
  phoneMatch: string = '/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/)'
  formRules: object = {
    name: [
      { required: true, message: '请输入医生姓名', trigger: 'blur' }
    ],
    phone: [
      { required: true, message: '请选择手机号作为账号', trigger: 'blur' },
      { 
        validator: (rule, value, callback) => {
          if (!/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(value)) {
            callback(new Error('请输入正确的手机号!'))
          } else {
            callback()
          }
        },
        trigger: ['blur', 'change'] 
      }
    ],
    gender: [
      { required: true, message: '请选择医生性别号', trigger: 'change' }
    ],
    email: [
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ],
    password: [
      { min: 6, message: '密码长度至少为6位', trigger: ['blur', 'change'] }
    ],
    group_id: [
      { required: true, message: '请为当前用户选择一个分组！', trigger: ['blur', 'change'] }
    ]
  }
  formLabelWidth: string = '140px'
  dialogTitle: string = ''
  loading: Boolean = false
  isShowClose: Boolean = false
  currentPhone: string = ''

  created() {
    bus.$on('memberChange', (val) => {
      this.memberType = val
    })
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.isLeader && this.isUserGroup && this.getProjGroups()
    this.urls = document.location.toString()
    this.judge(this.urls)
    this.doGroupSearch()
  }
  get isLeader () {
    return this.project.role === 'leader'
  }
   // ideal不展示
  get isUserGroup () {
    return (this.asideConfigs as any).user_group
  }
  getProjGroups () {
    let params = {
      projectId: this.project.id
    }
    this.projectGroups({ params }).catch((err) => {
      this.errorMsg(err.error.message)
    })
  }
  groupTrans (groupId) {
    let group: any = this.projGroups.filter((item: any) => {
      return item.id === groupId
    })
    return group.name
  }
  judge(val) {
    if(val.includes('groups')) {
      this.types = 'groups'
    } else {
      this.types = 'managers'
    }
  }
  changeRoleFuc() {
    if (this.user_ids.length === 0) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    this.$confirm('是否添加为管理员?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let params = {
        user_ids: this.user_ids,
        role: "professor",
        projectId : this.project.id
      }
      this.changeRole({ params }).then(() => {
        this.successMsg('添加成功!')
        this.doGroupSearch()
      })
    })
  }
  // 根据手机号搜索用户info
  remoteMethod (query) {
    let params = {
      projectId: (this.project as any).id,
      key: query
    }
    this.findUserBeLeader({ params }).then(() => {
      if (!this.keySearchLists.length) {
        (this.$refs['ruleForm'] as any).resetFields()
        let form = (this.form as any)
        form.phone = query
        this.isPasShow = true
      }
      this.currentPhone = query
    })
  }
  handleUserSelect () {
    // 匹配空格之后的电话号码
    let phone = (this.form as any).phone
    let currentInfo = (this.keySearchLists as any).find((item, i) => {
      return phone === item.phone
    })
    this.form = {
      ...currentInfo
    }
    this.isPasShow = false
  }
  handleUserBlur (query) {
    (this.form as any).phone = this.currentPhone
  }
  removeFuc() {
    if (this.user_ids.length === 0) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    (this.user_ids).map(val => {
      if( val != -1 ){
        this.$confirm('该组员将从管理员移出?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            user_ids: this.user_ids,
            role: 'doctor',
            projectId : this.project.id
          }
          this.changeRole({ params }).then(() => {
            this.successMsg('移除成功!')
            let flag = (this.user_ids as any).includes(this.account.id)
            if (flag) {
              this.$router.push('/')
            } else {
              this.doGroupSearch()
            }
          }).catch(() => {
            this.errorMsg('移除失败, 请稍后重试!')   
          })
        })
      }else{
        this.errorMsg('该用户无法移出!')
      }
    })
  }
  forbidFuc() {
    this.operateUserState(true)
  }
  enableFuc() {
    this.operateUserState(false)
  }
  addNewAdmin (type) {
    switch (type) {
      case 'doctor': 
        this.dialogTitle = '新增组员'
        break;
      case 'admin': 
        this.dialogTitle = '新增管理员'
        break;
    }
    this.role = type
    this.dialogFormVisible = !this.dialogFormVisible
  }
  cancel () {
    this.dialogFormVisible = !this.dialogFormVisible
    let PassForm = this.$refs.ruleForm as HTMLFormElement
    PassForm.resetFields()
  }
  // 新增用户 submit
  usersSubmit () {
    let PassForm = this.$refs.ruleForm as HTMLFormElement
    PassForm.validate((valid) => {
      if (valid) {
        this.form = Object.assign({}, this.form, {role: this.role})
        let params = {
          projectId: this.project.id,
          query: this.form
        }
        this.addUsers({ params }).then(() => {
          this.successMsg('新增用户成功!')
          this.dialogFormVisible = !this.dialogFormVisible
          PassForm.resetFields()
          this.verified = '0'
          this.$router.push(`/member/${this.types}?verified=0`)
          this.doGroupSearch()
        }).catch((err) => {
          this.errorMsg(err.error.message)
        })
      } else {
        return false
      }
    })
  }
  operateUserState (is_disabled_in_project) {
    let text = is_disabled_in_project ? '禁用' : '启用'
    if(this.user_ids == null) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    this.$confirm(`是否${text}该组员?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
      }).then(() => {
        let params = {
          user_ids: this.user_ids,
          is_disabled_in_project: is_disabled_in_project,
          projectId : this.project.id
        }
        this.forbiddenUser({ params }).then(()=>{
          this.successMsg(`${text}成功!`)
          // 禁用状态下 
          if (is_disabled_in_project) {
            let flag = (this.user_ids as any).includes(this.account.id)
            if (flag) {
              this.$router.push('/')
            } else {
              this.doGroupSearch()
            }
          } else {
            this.doGroupSearch()
          }
        })
      }).catch((err) => {
        this.errorMsg(err.error.message)
      })
  }

  keywordSearchFuc(val) {
    this.keyword = val
    this.pagination.page = 1
    this.doGroupSearch()
  }

  memberIdFuc(payload) {
    this.user_ids = payload.map(item => {
      return item.id
    })
  }

  onPagerSizeChange(val: string) {
    this.pagination.size = val
    this.doGroupSearch()
  }
  onPagerCurrentChange(val: string) {
    this.pagination.page = val
    this.doGroupSearch()
  }

  getVer(val) {
    this.verified = val.label === '未激活' ? '0' : '1'
    this.$router.push(`/member/${this.types}?verified=${this.verified}`)
    this.doGroupSearch()
  }
  roleChange (type) {
    switch (type) {
      case 'groups':
        return 'member'
      case 'managers':
        return 'admin'
    }
  }
  doGroupSearch() {
    this.loading = true
    let role = this.roleChange(this.types)
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = {
      limit: pagination.limit,
      offset: pagination.offset,
      role: role,
      verified: Number(this.verified),
      keyword: this.keyword,
      projectId : this.project.id
    }
    this.groupsList({ params }).then(() => {
      this.loading = false
    }).catch((err) => {
      this.errorMsg(err.error.message)
    })
  }

  @Watch('memberType') 
    getUrl(old: any, newValue: any) {
      this.keyword = ''
      this.pagination.page = 1
      this.pagination.size = 10
      this.urls = document.location.toString()
      this.verified = '1'
      this.judge(this.urls)
      this.doGroupSearch()
    }
  @Watch('$route.query.verified')
    onStatusChanged(val: any, oldVal: any) {
      if (val) {
        this.verified = val
      } else {
        this.verified = '1'
      }
    }
}
